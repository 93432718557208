import ApiService from "@/core/services/ApiService";
interface ILogScraping {
  scraper_name: string;
}

interface ILogCrawler {
  name: string;
  code: string;
  is_runing: boolean;
  status: number;
  _id: Map<string, string>;
}

interface ILogScrapingDetail {
  scraper_name: string;
  _id: Map<string, string>;
  start: Map<string, string>;
  end: Map<string, string>;
  duration: number;
  count: number;
  status: string;
  log: string;
}
interface ILogCrawlerDetail {
  project_name: string;
  _id: Map<string, string>;
  project_id: string;
  from: string;
  to: string;
  source: string;
  type: string;
  topic: string;
  keyword: string;
  timestamp: Map<string, string>;
  log: Map<string, number>;
  count: number;
  status: string;
}
interface ICrawler {
  created_at: string;
  project_name: string;
  data_type: string;
  end_date: string;
  executed_at?: string;
  file_name: string;
  finished_at?: string;
  id_project: string;
  id_user: string;
  start_date: string;
  username: string;
  status: string;
  stdout: string;
  topics?: Array<string>;
  topics_name?: Array<string>;
  _id: string;
}

const LogScraping: Array<ILogScraping> = [];
const LogScrapingDetail: Array<ILogScrapingDetail> = [];
const LogCrawler: Array<ILogCrawler> = [];
const LogCrawlerDetail: Array<ILogCrawlerDetail> = [];
const Crawler: Array<ICrawler> = [];
export {
  ILogScraping,
  ILogScrapingDetail,
  LogScrapingDetail,
  ILogCrawler,
  LogCrawler,
  ICrawler,
  Crawler,
  ILogCrawlerDetail,
  LogCrawlerDetail,
};

export default LogScraping;

export const getLogScraping = async () => {
  const response = await ApiService.post("/log/crawler-list", {});
  return response.data;
};

export const getLogScrapingDetail = async (payload) => {
  const response = await ApiService.post("/log/crawler-detail", {
    data: payload,
  });
  return response.data;
};

export const getLogCrawler = async () => {
  const response = await ApiService.post("/log/medmon-crawler-list", {});
  return response.data;
};
export const getLogCrawlerDetail = async (payload) => {
  const response = await ApiService.post("/log/medmon-crawler-detail", {
    data: payload,
  });
  return response.data;
};
export const getCrawler = async (data: any) => {
  const params = data;
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  const url = `${process.env.VUE_APP_API_CRAWLER_URL}/siputra/crawlback?${query}`;
  const response = await ApiService.get(url);
  return response;
};
export const retryCrawler = async (id: string) => {
  const url = `${process.env.VUE_APP_API_CRAWLER_URL}/siputra/crawlback-retry/${id}`;
  const response = await ApiService.put(url, {});
  return response;
};
export const forceStopCrawler = async (id: string) => {
  const url = `${process.env.VUE_APP_API_CRAWLER_URL}/siputra/crawlback-force-stop/${id}`;
  const response = await ApiService.put(url, {});
  return response;
};
export const deleteCrawler = async (id: string) => {
  const url = `${process.env.VUE_APP_API_CRAWLER_URL}/siputra/crawlback-delete/${id}`;
  const response = await ApiService.delete(url);
  return response;
};
